import { React, Fragment, useState, useEffect, useRef } from 'react'
import 'react-icons/md'
import { MdFastForward, MdFastRewind } from 'react-icons/md';
import './Calendar.css';
import TimePicker from 'react-time-picker'

export default function CalendarView({details, admin, snowschool}) {
    
    const [canViewDetails, setCanViewDetails] = useState(false)
    const [isAdmin, setIsAdmin] = useState(false)
    const [isSnowSchool, setIsSnowSchool] = useState(false)
    const [showAddForm, setShowAddForm] = useState(false)
    const [showFindForm, setShowFindForm] = useState(false)
    const [showManagerForm, setShowManagerForm] = useState(false)
    const [finderYearBookings, setFinderYearBookings] = useState([])
    const [finderSelectedSchool, setFinderSelectedSchool] = useState("")
    const [selectedDay, setSelectedDay] = useState(0)
    const [calendarData, setCalendarData] = useState((<></>))
    const [currentYear, setCurrentYear] = useState(new Date(Date.now()).getFullYear())
    const [currentMonth, setCurrentMonth] = useState(new Date(Date.now()).getMonth())
    const [currentData, setCurrentData] = useState([])

    // SCHOOL MANAGEMENT DATA setManagerShowAddSchoolForm
    const [managerShowAddSchoolForm, setManagerShowAddSchoolForm] = useState(false)
    function clearManagerSchoolForm() {
        setManagerShowAddSchoolForm(true)
        setManagerSchoolId(0)
        setManagerSchoolName("")
        setManagerSchoolAddress1("")
        setManagerSchoolAddress2("")
        setManagerSchoolEmail("")
        setManagerSchoolPostalCode("")
        setManagerSchoolContact("")
        setManagerSchoolContactPhone("")

    }
    const [managerSchoolList, setManagerSchoolList] = useState([])
    
    const [managerSelectedSchool, setManagerSelectedSchool] = useState("")
    function updateManagerSelectedSchool(name) {
        setManagerShowAddSchoolForm(false)
        setManagerSelectedSchool(name)

        // Load Data into Form Fields
        for(var i=0;i<managerSchoolList.length;i++) {
            if(managerSchoolList[i].school_name === name) {
                // console.log(managerSchoolList[i])
                setManagerSchoolId(managerSchoolList[i].school_id)
                setManagerSchoolName(managerSchoolList[i].school_name)
                setManagerSchoolAddress1(managerSchoolList[i].school_address1 ?? "")
                setManagerSchoolAddress2(managerSchoolList[i].school_address2 ?? "")
                setManagerSchoolEmail(managerSchoolList[i].school_email)
                setManagerSchoolPostalCode(managerSchoolList[i].school_postalcode ?? "")
                setManagerSchoolContact(managerSchoolList[i].school_contact ?? "")
                setManagerSchoolContactPhone(managerSchoolList[i].school_contact_phone ?? "")
            }
        }
    }



    // School Management -> Edit Fields
    const [managerSchoolId, setManagerSchoolId] = useState(0)
    const [managerSchoolName, setManagerSchoolName] = useState("")

    const [managerSchoolAddress1, setManagerSchoolAddress1] = useState("")
    const [managerSchoolAddress2, setManagerSchoolAddress2] = useState("")
    const [managerSchoolPostalCode, setManagerSchoolPostalCode] = useState("")
    const [managerSchoolEmail, setManagerSchoolEmail] = useState("")
    const [managerSchoolPhone, setManagerSchoolPhone] = useState("")

    const [managerSchoolContact, setManagerSchoolContact] = useState("")
    const [managerSchoolContactPhone, setManagerSchoolContactPhone] = useState("")

    // ADMIN FORM DATA
    const [schoolList, setSchoolList] = useState([])
    const [editSchoolData, setEditSchoolData] = useState(null)  
    const [schoolId, setSchoolId] = useState(0)
    const [bookingId, setBookingId] = useState(null)
    const [bookingNotes, setBookingNotes] = useState("")
    const [bookingContactPhone, setBookingContactPhone] = useState("")
    const [bookingContactName, setBookingContactName] = useState("")
    const [bookingContactEmail, setBookingContactEmail] = useState("")
    const [bookingContact2Phone, setBookingContact2Phone] = useState("")
    const [bookingContact2Name, setBookingContact2Name] = useState("")
    const [bookingContact2Email, setBookingContact2Email] = useState("")
    const [bookingGroup, setBookingGroup] = useState(10)
    const [bookingLoGrade, setBookingLoGrade] = useState(7)
    const [bookingHiGrade, setBookingHiGrade] = useState(9)
    const [bookingArrival, setBookingArrival] = useState("10:00")
    const [bookingDeparture, setBookingDeparture] = useState("14:00")
    const [bookingSki, setBookingSki] = useState(0)
    const [bookingSnowboard, setBookingSnowboard] = useState(0)
    const [bookingTube, setBookingTube] = useState(0)
    const [bookingLiftOnly, setBookingLiftOnly] = useState(0)
    const [bookingAddTube, setBookingAddTube] = useState(0)
    const [bookingDisabled, setBookingDisabled] = useState(0)
    const [bookingDisabledNotes, setBookingDisabledNotes] = useState("")
    const [bookingPizza, setBookingPizza] = useState(0)
    const [bookingPizzaTime, setBookingPizzaTime] = useState("12:00")

    function ResetFormData() {
        setSchoolId(0)
        setBookingId(null)
        setBookingNotes("")
        setBookingContactPhone("")
        setBookingContactName("")
        setBookingContactEmail("")
        setBookingContact2Phone("")
        setBookingContact2Name("")
        setBookingContact2Email("")
        setBookingGroup(10)
        setBookingLoGrade(7)
        setBookingHiGrade(9)
        setBookingArrival("")
        setBookingDeparture("")
        setBookingSki(0)
        setBookingSnowboard(0)
        setBookingTube(0)
        setBookingLiftOnly(0)
        setBookingAddTube(0)
        setBookingDisabled(0)
        setBookingDisabledNotes("")
        setBookingPizza(0)
        setBookingPizzaTime("")
    }

    // Get School List
    async function GetSchoolList() {
        await fetch('https://mrwp.kardall.com/schools/list', {
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                'Access-Control-Allow-Origin':'*'
        }}).then( r => r.json())
        .then( response => {
            // console.log(response.data)
            setSchoolList(response.data)
        })
    }

    function nextYear() {
        setCurrentYear( currentYear + 1 )
    }

    function nextMonth() {
        var nMonth = currentMonth + 1
        if(nMonth > 11) {
            nMonth = 0
            var nYear = currentYear + 1
            setCurrentYear(nYear)
        }

        setCurrentMonth( nMonth )
    }

    function prevYear() {
        setCurrentYear( currentYear - 1 )
    }

    function prevMonth() {
        var nMonth = currentMonth - 1
        if(nMonth < 0) {
            nMonth = 11
            var nYear = currentYear - 1
            setCurrentYear(nYear)
        }

        setCurrentMonth( nMonth )
    }

    function FindBooking() {
        setShowFindForm(true)
    }

    function ManageSchools() {
        setShowManagerForm(true)
    }

    useEffect( () => {
        GenerateCalendar(currentYear, currentMonth)
        
    }, [currentYear, currentMonth])

    function GetMonthData(year, month) {
        // let date = new Date(Date.parse(date_string))
        // let month = date.getMonth()
        // let year = date.getFullYear()
        // setCurrentYear(year)
        // setCurrentMonth(month)
        return new Promise( async (resolve, reject) => {
            try {
                await fetch('https://mrwp.kardall.com/counts', {
                    method: 'GET',
                    headers: {
                        'Content-Type':'application/json',
                        'Access-Control-Allow-Origin':'*',
                        'year':year,
                        'month':month+1
                    }
                })
                .then( r => r.json())
                .then( results => {
                    // Create Days month object
                    // Get Total Days In Month
                    //var daysInMonth = new Date(year,month,0).getDate()
                    //resolve(daysInMonth)
                    resolve(results)
                })
                .catch( err => {
                    reject(err)
                })

            } catch(err) {
                console.log(err)
            }
        })
    }


    const toggleBookingConfirmed = async (id) => {
        var now = new Date(Date.now())
        fetch('https://mrwp.kardall.com/toggleconfirmed/'+id+'/'+now.getFullYear()+'/'+now.getMonth()+'/'+now.getDay(), {
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                'Access-Control-Allow-Origin':'*'
        }}).then( r => r.json())
        .then( response => {
            setCurrentData([])
            GenerateCalendar(currentYear, currentMonth)
        })
    }

    const ToggleBooking = async (v) => {
        console.log('https://mrwp.kardall.com/togglebooking/'+v.year+'/'+v.month+'/'+v.day)
        fetch('https://mrwp.kardall.com/togglebooking/'+v.year+'/'+v.month+'/'+v.day, {
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                'Access-Control-Allow-Origin':'*'
        }}).then( r => r.json())
        .then( response => {
            GenerateCalendar(currentYear, currentMonth)
        })
            
    }
    const ShowDetails = async (v) => {
        // alert(JSON.stringify(v))
        setSelectedDay(v.day)
        fetch('https://mrwp.kardall.com/details/'+v.year+'/'+v.month+'/'+v.day, {
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                'Access-Control-Allow-Origin':'*'
        }}).then( r => r.json())
        .then( response => {
            setCurrentData(response.data)
        })
        
    }

    async function GenerateCalendar(year, month) {
        setCanViewDetails(details)
        setIsAdmin(admin !== undefined ? admin : false)
        setIsSnowSchool(snowschool !== undefined ? snowschool : false)
        var c = []
        var firstOfMonth = new Date(year,month,1).getDay()
        for(var i=0;i<firstOfMonth;i++) {
            c.push((<td key={"Spacer" + i} className="day other-month"></td>))
        }
        var md = await GetMonthData(year,month)
        // console.log(list)
        var daysInMonth = new Date(year,month+1,0).getDate()
        var d = []
        
        var calendar_schedule = []
        for(var day=1;day<=daysInMonth;day++) {
            var c_day = {
                "day": day,
                "booking_allowed":0
            }
            if(md.hasOwnProperty(day)) {
                var valid_schools = md[day].filter(b => b.school_name !== null)
                // get total counts for the day
                var total_group_size = valid_schools.map(b => b.group_size).reduce( (a,b) => a + b,0)
                var total_snowschool_bookings = valid_schools.map(b => b.snowschool_bookings).reduce((a,b) => a + b, 0)
                var total_tube_bookings = valid_schools.map(b => b.tube_bookings).reduce((a,b) => a + b, 0)
                var total_addtube_bookings = valid_schools.map(b => b.addtube_bookings).reduce((a,b) => a + b, 0)
                var school_list = valid_schools.map(b => {
                    return [b.id, b.school_name, b.grade_range, (b.snowschool_bookings + b.tube_bookings), b.ski, b.snowboard, (b.tube_bookings + b.addtube_bookings), b.pizza_lunches]
                })
                c_day.booking_allowed = md[day][0].booking_allowed
                c_day['total_snowschool'] = total_snowschool_bookings
                c_day['total_tube'] = total_tube_bookings + total_addtube_bookings
                c_day['group_size'] = total_group_size
                c_day['school_list'] = school_list
                
            }
            calendar_schedule.push(c_day)
        }
        console.log(calendar_schedule)
        calendar_schedule.map(c_day => {
            if(c_day.booking_allowed) {
                // Encorporate max daily limit into calendar view
                // Lets say, minimum group booking size is for 10 people for a school group
                // If the daily total + 10 >= max limit then display a 'Full' label?
                var max = 200
                var tentative_max = c_day.group_size + 10
                if(tentative_max >= max) {
                    // Can we display details?
                    if(isAdmin || admin) {
                        d.push((<td key={currentYear + '-' + c_day.day} className="day"><div className="date">{c_day.day}</div><div onClick={() => ShowDetails({day: c_day.day, year: currentYear, month: (currentMonth + 1)}) } className="event full"><div className="event-desc">Booked {c_day.group_size}  / 200 <br/>(Fully Booked)</div>{c_day.school_list.map(s => {console.log(s);return (<div className="admin-school-entry" title={`Snow School: ${s[4]+s[5]}\nSki: ${s[4]}\nSnowboard: ${s[5]}\nTube: ${s[6]}\nPizza: ${s[7]}`} key={s[0]}>{s[1].substring(0,9) + " [ " + s[2] + " ] (" + s[6] + ")"}</div>)})}</div></td>))
                    } else {
                        if(canViewDetails || details || isSnowSchool || snowschool) {
                            d.push((<td key={currentYear + '-' + c_day.day} className="day"><div className="date">{c_day.day}</div><div onClick={() => ShowDetails({day: c_day.day, year: currentYear, month: (currentMonth + 1)}) } className="event full"><div onClick={() => ShowDetails({day: c_day.day, year: currentYear, month: (currentMonth + 1)}) } className="event-desc">Booked {c_day.group_size} / 200 <br/>(Fully Booked)</div></div></td>))
                        } else {
                            d.push((<td key={currentYear + '-' + c_day.day} className="day"><div className="date">{c_day.day}</div><div className="event full"><div className="event-desc">Booked {c_day.group_size}  / 200 <br/>(Fully Booked)</div></div></td>))
                        }
                    }
                } else {
                    if(isAdmin || admin) {
                        d.push((<td key={currentYear + '-' + c_day.day} className="day"><div className="date">{c_day.day}</div>{c_day.group_size === 0 ? <button onClick={() => ToggleBooking({day: c_day.day, year: currentYear, month: (currentMonth + 1)}) }>Toggle</button> : "" }<div onClick={() => ShowDetails({day: c_day.day, year: currentYear, month: (currentMonth + 1)}) } className="event"><div className="event-desc">Booked {c_day.group_size}  / 200</div>{c_day.school_list.map(s => {return (<div className="admin-school-entry" title={`Snow School: ${s[4]+s[5]}\nSki: ${s[4]}\nSnowboard: ${s[5]}\nTube: ${s[6]}\nPizza: ${s[7]}`} key={s[0]}>{s[1].substring(0,9) + " [ " + s[2] + " ] (" + s[6] + ")"}</div>)})}</div></td>))
                    } else {
                        if(canViewDetails || details || isSnowSchool || snowschool) {
                            d.push((<td key={currentYear + '-' + c_day.day} className="day"><div className="date">{c_day.day}</div><div onClick={() => ShowDetails({day: c_day.day, year: currentYear, month: (currentMonth + 1)}) } className="event"><div className="event-desc">Booked {c_day.group_size} / 200 </div></div></td>))
                        } else {
                            d.push((<td key={currentYear + '-' + c_day.day} className="day"><div className="date">{c_day.day}</div><div className="event"><div className="event-desc">Booked {c_day.group_size} / 200 </div></div></td>))
                        }
                    }
                }
            } else {
                if(isAdmin || admin) {
                    d.push((<td key={currentYear + '-' + c_day.day} className="day"><div className="date">{c_day.day}</div><button onClick={() => ToggleBooking({day: c_day.day, year: currentYear, month: (currentMonth + 1)}) }>Toggle</button><div className="event no-bookings"><div className="event-desc">Not Available</div></div></td>))
                } else {
                    d.push((<td key={currentYear + '-' + c_day.day} className="day"><div className="date">{c_day.day}</div><div className="event no-bookings"><div className="event-desc">Not Available</div></div></td>))
                }
                
            }
        })

        var totalSlots = [...c, ...d]

        let rows = []
        let cells = []

        totalSlots.forEach( (row, i) => {
            if(i % 7 !== 0) {
                cells.push(row)
            } else {
                rows.push(cells)
                cells = []
                cells.push(row)
            }
            if(i === totalSlots.length - 1) {
                rows.push(cells)
            }

        })

        let calendar = rows.map((d,i) => {
            if(i === 0) return ""
            return <tr key={"week" + i}>{d}</tr>
        })
        setCalendarData(calendar)
    }

    function GenerateModalField(label, val) {
        var display_label = ""

        switch(label) {
            case "booking_contact_name": display_label = "Contact"; break;
            case "booking_contact2_name": display_label = "Contact 2"; break;
            case "booking_confirmed": display_label = "Confirmed On"; break;
            case "school_name": display_label = "School Name"; break;
            case "booking_group": display_label = "Group Size"; break;
            case "booking_arrival": display_label = "Arrival"; break;
            case "booking_pizza": display_label = "Pizza Lunches"; break;
            default: display_label = label;
        }

        const hourConversion = (time) => {
            // Check correct time format and split into components
            time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

            if (time.length > 1) { // If time format correct
                time = time.slice (1);  // Remove full string match value
                time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
                time[0] = +time[0] % 12 || 12; // Adjust hours
            }
            return time.join (''); // return adjusted time or original string
        }
        return (
                <div key={display_label +":"+val} className="field-entry">
                    <div className="field-label">{display_label}</div>
                    <div className="field-value">{ label === "booking_arrival" ? hourConversion(val) : val}</div>
                </div>
            )
    }

    function GenerateModalData() {
        // console.log(currentData)
        if(currentData.length === 1) {
            // Sanity Check to see if there any bookings at all so we don't display null entries
            if(currentData[0].booking_group === 0) return
        }
        let field_entries = []

        for(var i=0;i<currentData.length;i++) {
            if(currentData[i].booking_school_id === null) { 
                continue;
             }
             var booking_info = (
                <div key={"booking:"+currentData[i].booking_school_id} className="booking">
                    <div key={"bookingcontainer:"+currentData[i].booking_school_id} className="booking-container">
                        <div key={"bookingdetails:"+currentData[i].booking_school_id} className="booking-details">
                            {GenerateModalField("Group",currentData[i].school_name)}
                            {GenerateModalField("Size",currentData[i].booking_group)}
                            <div key={currentData[i].booking_school_id +":Arrival"} className="field-entry">
                                <div className="field-label">Arrival</div>
                                <div className="field-value">
                                    <TimePicker disabled={true} disableClock={true} clearIcon={null} clockIcon={null} amPmAriaLabel="Select AM/PM" value={currentData[i].booking_arrival} />
                                </div>
                            </div>
                            <div key={currentData[i].booking_school_id +":Departure"} className="field-entry">
                                <div className="field-label">Departure</div>
                                <div className="field-value">
                                    <TimePicker disabled={true} disableClock={true} clearIcon={null} clockIcon={null} amPmAriaLabel="Select AM/PM" value={currentData[i].booking_departure} />
                                </div>
                            </div>
                        </div>
                        <div key={"counts:"+currentData[i].booking_school_id}className="booking-details">
                            {GenerateModalField("Lo Grade",currentData[i].booking_lograde)}
                            {GenerateModalField("Hi Grade",currentData[i].booking_higrade)}
                        </div>
                        {/* <div key={"contact1:"+currentData[i].booking_school_id} className="booking-details">
                            {GenerateModalField("Contact",currentData[i].booking_contact_name)}
                            {GenerateModalField("Phone",currentData[i].booking_contact_phone)}
                            {GenerateModalField("Email",currentData[i].booking_contact_email)}
                        </div>
                        <div key={"contact2:"+currentData[i].booking_school_id}className="booking-details">
                            {GenerateModalField("Contact 2",currentData[i].booking_contact2_name)}
                            {GenerateModalField("Phone 2",currentData[i].booking_contact2_phone)}
                            {GenerateModalField("Email 2",currentData[i].booking_contact2_email)}
                        </div> */}
                        <div key={"pizza:"+currentData[i].booking_school_id} className="booking-details">
                            {GenerateModalField("Pizza Lunches",currentData[i].booking_pizza)}
                            {GenerateModalField("Tube",currentData[i].booking_tube)}
                            <div key={currentData[i].booking_school_id +":pizza-time"} className="field-entry">
                                <div className="field-label">Lunch Time</div>
                                <div className="field-value">
                                    <TimePicker disabled={true} disableClock={true} clearIcon={null} clockIcon={null} amPmAriaLabel="Select AM/PM" value={currentData[i].booking_pizza_time} />
                                </div>
                            </div>
                        </div>
                        <div key={"notes:"+currentData[i].booking_school_id} className="booking-details">
                            {GenerateModalField("Booking Notes",currentData[i].booking_notes)}
                        </div>
                        <div key={"confirmed:"+currentData[i].booking_school_id} className="booking-details">
                            {
                                currentData[i].booking_confirmed === 1 ? "confirmed" : "not confirmed"
                                // currentData[i].booking_confirmed_date
                                // : 
                                // <button onClick={() => toggleBookingConfirmed({day: new Date(Date.now()).getDay, year: new Date(Date.now()).getFullYear, month: new Date(Date.now()).getMonth})}>{currentData[i].booking_confirmed_date}</button>
                            }
                            
                        </div>
                    </div>
                </div>
            )
            field_entries.push(booking_info)
            
        }
        return (field_entries.map( f => { return f }))
        
    }

    function CreateSnowSchoolModal() {
        // console.log(currentData)
        if(currentData.length === 1) {
            // Sanity Check to see if there any bookings at all so we don't display null entries
            if(currentData[0].booking_group === 0) return
        }
        let field_entries = []

        for(var i=0;i<currentData.length;i++) {
            if(currentData[i].booking_school_id === null) { 
                continue;
             }
             var booking_info = (
                <><div className="booking">
                    <div className="booking-container">
                        <div className="booking-details">
                            {GenerateModalField("Group",currentData[i].school_name)}
                            {GenerateModalField("Size",currentData[i].booking_group)}
                            <div key={currentData[i].booking_school_id +":Arrival"} className="field-entry">
                                <div className="field-label">Arrival</div>
                                <div className="field-value">
                                    <TimePicker disabled={true} disableClock={true} clearIcon={null} clockIcon={null} amPmAriaLabel="Select AM/PM" value={currentData[i].booking_arrival} />
                                </div>
                            </div>
                            <div key={currentData[i].booking_school_id +":Departure"} className="field-entry">
                                <div className="field-label">Departure</div>
                                <div className="field-value">
                                    <TimePicker disabled={true} disableClock={true} clearIcon={null} clockIcon={null} amPmAriaLabel="Select AM/PM" value={currentData[i].booking_departure} />
                                </div>
                            </div>
                        </div>
                        <div className="booking-details">
                            {GenerateModalField("Contact",currentData[i].booking_contact_name)}
                            {GenerateModalField("Phone",currentData[i].booking_contact_phone)}
                            {GenerateModalField("Email",currentData[i].booking_contact_email)}
                        </div>
                        <div className="booking-details">
                            {GenerateModalField("Contact 2",currentData[i].booking_contact2_name)}
                            {GenerateModalField("Phone 2",currentData[i].booking_contact2_phone)}
                            {GenerateModalField("Email 2",currentData[i].booking_contact2_email)}
                        </div>
                        <div className="booking-details">
                            {GenerateModalField("Ski",currentData[i].booking_ski)}
                            {GenerateModalField("Snowboard",currentData[i].booking_snowboard)}
                            {GenerateModalField("Tube",currentData[i].booking_tube)}
                            {GenerateModalField("Add Tube",currentData[i].booking_addtube)}
                            {GenerateModalField("Disabled",currentData[i].booking_disabled)}
                            {GenerateModalField("Disabled Notes",currentData[i].booking_disabled_notes)}
                        </div>
                        <div className="booking-details">
                            {GenerateModalField("LoGrade",currentData[i].booking_lograde)}
                            {GenerateModalField("HiGrade",currentData[i].booking_higrade)}
                        </div>
                        <div className="booking-details">
                            {GenerateModalField("Booking Notes",currentData[i].booking_notes)}
                        </div>
                        <div className="booking-details">
                            {
                                currentData[i].booking_confirmed === 1 ? "confirmed" : "not confirmed"
                                // currentData[i].booking_confirmed_date
                                // : 
                                // <button onClick={() => toggleBookingConfirmed({day: new Date(Date.now()).getDay, year: new Date(Date.now()).getFullYear, month: new Date(Date.now()).getMonth})}>{currentData[i].booking_confirmed_date}</button>
                            }
                            
                        </div>
                    </div>
                </div></>
            )
            field_entries.push(booking_info)
            // console.log(booking_info)
            
        }
        return (field_entries.map( f => { return f }))
        
    }

    function EditEntry(e) {
        setEditSchoolData(e)
        setSchoolId(e.booking_school_id)
        setBookingId(e.booking_id)
        setBookingNotes(e.booking_notes)
        setBookingContactPhone(e.booking_contact_phone)
        setBookingContactName(e.booking_contact_name)
        setBookingContactEmail(e.booking_contact_email)
        setBookingContact2Phone(e.booking_contact2_phone)
        setBookingContact2Name(e.booking_contact2_name)
        setBookingContact2Email(e.booking_contact2_email)
        setBookingGroup(e.booking_group)
        setBookingLoGrade(e.booking_lograde)
        setBookingHiGrade(e.booking_higrade)
        setBookingArrival(e.booking_arrival)
        setBookingDeparture(e.booking_departure)
        setBookingSki(e.booking_ski)
        setBookingSnowboard(e.booking_snowboard)
        setBookingTube(e.booking_tube)
        setBookingLiftOnly(e.booking_liftonly)
        setBookingAddTube(e.booking_addtube)
        setBookingDisabled(e.booking_disabled)
        setBookingDisabledNotes(e.booking_disabled_notes)
        setBookingPizza(e.booking_pizza)
        setBookingPizzaTime(e.booking_pizza_time)
    }

    function DeleteEntry(id) {
        fetch('/deletebooking', {
            method: 'POST',
            headers: {
                'Content-Type':'application/json',
                'Access-Control-Allow-Origin':'*'
            },
            body: JSON.stringify({
                booking_id: id
            })
            
        }).then( () => {
            setShowAddForm(false)
            setEditSchoolData(null)
            ResetFormData()
            setCurrentData([])
            GenerateCalendar(currentYear, currentMonth)
        })
    }
    function GenerateAdminModalData() {
        if(currentData.length === 1) {
            // Sanity Check to see if there any bookings at all so we don't display null entries
            if(currentData[0].booking_group === 0) return
        }
        let field_entries = []
        for(var i=0;i<currentData.length;i++) {
            if(currentData[i].booking_school_id === null) continue;
            
            var data_edit = currentData[i]
            field_entries.push(
                (
                    <div key={"booking:"+data_edit.booking_school_id} className="booking">
                        <div className="booking-container">
                            <div className="booking-commands">
                                <button key={"btn:"+data_edit.booking_id} data={JSON.stringify(data_edit)} onClick={(v) => { EditEntry( JSON.parse(v.target.getAttribute("data")))} } >Edit</button><button data={JSON.stringify(data_edit)} onClick={(v) => { var id = (JSON.parse(v.target.getAttribute("data"))).booking_id; console.log(id); DeleteEntry(id); }}>Delete</button>
                            </div>
                            <div className="booking-details">
                                {GenerateModalField("Group",data_edit.school_name)}
                                {GenerateModalField("Size",data_edit.booking_group)}
                                <div key={data_edit.booking_school_id +":Arrival"} className="field-entry">
                                    <div className="field-label">Arrival</div>
                                    <div className="field-value">
                                        <TimePicker disabled={true} disableClock={true} clearIcon={null} clockIcon={null} amPmAriaLabel="Select AM/PM" value={data_edit.booking_arrival} />
                                    </div>
                                </div>
                                <div key={data_edit.booking_school_id +":Departure"} className="field-entry">
                                    <div className="field-label">Departure</div>
                                    <div className="field-value">
                                        <TimePicker disabled={true} disableClock={true} clearIcon={null} clockIcon={null} amPmAriaLabel="Select AM/PM" value={data_edit.booking_departure} />
                                    </div>
                                </div>
                            </div>
                            <div className="booking-details">
                                {GenerateModalField("Contact",data_edit.booking_contact_name)}
                                {GenerateModalField("Phone",data_edit.booking_contact_phone)}
                                {GenerateModalField("Email",data_edit.booking_contact_email)}
                            </div>
                            <div className="booking-details">
                                {GenerateModalField("Contact 2",data_edit.booking_contact2_name)}
                                {GenerateModalField("Phone 2",data_edit.booking_contact2_phone)}
                                {GenerateModalField("Email 2",data_edit.booking_contact2_email)}
                            </div>
    
                            <div className="booking-details">
                                {GenerateModalField("Lo Grade",data_edit.booking_lograde)}
                                {GenerateModalField("Hi Grade",data_edit.booking_higrade)}
                                {GenerateModalField("Ski",data_edit.booking_ski)}
                                {GenerateModalField("Snowboard",data_edit.booking_snowboard)}
                                {GenerateModalField("Tube",data_edit.booking_tube)}
                            </div>
    
                            <div className="booking-details">
                                {GenerateModalField("Add Tube",data_edit.booking_addtube)}
                                {GenerateModalField("Lift Only",data_edit.booking_liftonly)}
                                {GenerateModalField("Disabled",data_edit.booking_disabled)}
                                {GenerateModalField("Disabled Notes",data_edit.booking_disabled_notes)}
                            </div>
                            <div className="booking-details">
                                {GenerateModalField("Pizza Lunches",data_edit.booking_pizza)}
                                <div key={data_edit.booking_school_id +":Departure"} className="field-entry">
                                    <div className="field-label">Lunch Time</div>
                                    <div className="field-value">
                                        <TimePicker disabled={true} disableClock={true} clearIcon={null} clockIcon={null} amPmAriaLabel="Select AM/PM" value={data_edit.booking_pizza_time} />
                                    </div>
                                </div>
                                
                            </div>
                            <div className="booking-details">
                                {GenerateModalField("Booking Notes",data_edit.booking_notes)}
                            </div>
                            <div className="booking-details">
                                <button className="booking-confirm-btn" sid={data_edit.booking_id} onClick={(o) => {toggleBookingConfirmed(o.target.getAttribute('sid'));}}>{ data_edit.booking_confirmed_date === null ? "Confirm" : "Confirmed " + new Date(Date.parse(data_edit.booking_confirmed_date)).toDateString()}</button>
                            </div>
                        </div>
                    </div>
                )
            )
            
        }
        return (field_entries.map( f => { return f }))
        
    }

    function MonthToString(month) {
        const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
        ];
        return monthNames[month]
    }

    function CreateModalData() {
        if(currentData === undefined) return (<></>)
        if(currentData.length === 0) return (<></>)
        return (
            <div className="modal">
                <button className="modal-close-button" onClick={() => setCurrentData([])}>Close</button>
                {
                        isSnowSchool ? CreateSnowSchoolModal() : GenerateModalData()
                }
            </div>
            )
    }

    function CancelEdit() {
        setEditSchoolData(null)
    }

    function setEditField(field,value) {
        var t = editSchoolData
        t[field] = value
        setEditSchoolData(t)
    }
    
    const edit_form_html = () => {
        return (
            <Fragment>
            <div className="booking-new-container">
                <div className="booking-row">
                    <div className="booking-col">
                        <h3>Editing &#91; {editSchoolData.school_name} &#93; for { currentYear + "-" + (currentMonth+1).toString().padStart(2,'0') + "-" + selectedDay.toString().padStart(2,'0')}</h3>
                    </div>
                </div>
                <div className="booking-row contact">
                    <div className="booking-col">
                        <label>Contact Name</label>
                        <input type="text" value={bookingContactName} onChange={(e) => setBookingContactName(e.target.value)}></input>
                    </div>
                    <div className="booking-col">
                        <label>Contact Phone</label>
                        <input type="text" value={bookingContactPhone} onChange={(e) => setBookingContactPhone(e.target.value)}></input>
                    </div>
                    <div className="booking-col">
                        <label>Contact Email</label>
                        <input type="text" value={bookingContactEmail} onChange={(e) => setBookingContactEmail(e.target.value)}></input>
                    </div>
                </div>
                <div className="booking-row contact">
                    <div className="booking-col">
                        <label>Contact 2 Name</label>
                        <input type="text" value={bookingContact2Name} onChange={(e) => setBookingContact2Name(e.target.value)}></input>
                    </div>
                    <div className="booking-col">
                        <label>Contact 2 Phone</label>
                        <input type="text" value={bookingContact2Phone} onChange={(e) => setBookingContact2Phone(e.target.value)}></input>
                    </div>
                    <div className="booking-col">
                        <label>Contact 2 Email</label>
                        <input type="text" value={bookingContact2Email} onChange={(e) => setBookingContact2Email(e.target.value)}></input>
                    </div>
                </div>
                <div className="booking-row">
                    <div className="booking-col">
                        <label>Size</label>
                        <input type="number" value={bookingGroup} onChange={(e) => setBookingGroup(e.target.value)}></input>
                    </div>
                    <div className="booking-col">
                        <label>Arrival Time</label>
                        {/* <input type="text" value={bookingArrival} onChange={(e) => setBookingArrival(e.target.value)}></input> */}
                        <TimePicker amPmAriaLabel="Select AM/PM" disableClock={true} disableIcon={true} value={editSchoolData.booking_arrival} onChange={(e) => setBookingArrival(e)} />
                    </div>
                    <div className="booking-col">
                        <label>Departure Time</label>
                        {/* <input type="text" value={bookingDeparture}  onChange={(e) => setBookingDeparture(e.target.value)}></input> */}
                        <TimePicker amPmAriaLabel="Select AM/PM" disableClock={true} disableIcon={true} value={editSchoolData.booking_departure} onChange={(e) => setBookingDeparture(e)} />
                    </div>
                </div>
                <div className="booking-row">
                    <div className="booking-col">
                        <label>Lo Grade</label>
                        <input type="number" value={bookingLoGrade} onChange={(e) => setBookingLoGrade(e.target.value)}></input>
                    </div>
                    <div className="booking-col">
                        <label>Hi Grade</label>
                        <input type="number" value={bookingHiGrade} onChange={(e) => setBookingHiGrade(e.target.value)}></input>
                    </div>
                    <div className="booking-col">
                        <label>Ski</label>
                        <input type="number" value={bookingSki} onChange={(e) => setBookingSki(e.target.value)}></input>
                    </div>
                    <div className="booking-col">
                        <label>Snowboard</label>
                        <input type="number" value={bookingSnowboard} onChange={(e) => setBookingSnowboard(e.target.value)}></input>
                    </div>
                    <div className="booking-col">
                        <label>Tube</label>
                        <input type="number" value={bookingTube} onChange={(e) => setBookingTube(e.target.value)}></input>
                    </div>
                </div>
                <div className="booking-row">
                    <div className="booking-col">
                        <label>Pizza Lunches</label>
                        <input type="number" value={bookingPizza} onChange={(e) => setBookingPizza(e.target.value)}></input>
                    </div>
                    <div className="booking-col">
                        <label>Lunch Time</label>
                        <TimePicker disableClock={true} clearIcon={null} clockIcon={null} amPmAriaLabel="Select AM/PM" value={editSchoolData.booking_pizza_time} onChange={(e) => setBookingPizzaTime(e)} />
                    </div>
                    <div className="booking-col">
                        <label>Disabled</label>
                        <input type="number" value={bookingDisabled} onChange={(e) => setBookingDisabled(e.target.value)}></input>
                    </div>
                    <div className="booking-col">
                        <label>Disabled Notes</label>
                        <textarea value={bookingDisabledNotes} onChange={(e) => setBookingDisabledNotes(e.target.value)}></textarea>
                    </div>
                    <div className="booking-col">
                        <label>Lift Only</label>
                        <input type="number" value={bookingLiftOnly} onChange={(e) => setBookingLiftOnly(e.target.value)}></input>
                    </div>
                    <div className="booking-col">
                        <label>Add Tube</label>
                        <input type="number" value={bookingAddTube} onChange={(e) => setBookingAddTube(e.target.value)}></input>
                    </div>
                </div>
                <div className="booking-row">
                    <div className="booking-col">
                        <label>Booking Notes</label>
                        <textarea value={bookingNotes} onChange={(e) => setBookingNotes(e.target.value)}></textarea>
                    </div>
                </div>
                <div className="booking-row">
                    <div className="booking-col">
                        <button className="save" onClick={() => SaveBooking()}>Save</button>
                    </div>
                    <div className="booking-col">
                        <button className="cancel" onClick={() => CancelEdit()}>Cancel</button>
                    </div>
                </div>
            </div>
            </Fragment>
        )
    }

    async function getBookingsForCurrentYear() {
        await fetch('https://mrwp.kardall.com/yearbookings', {
                method: 'GET',
            })
            .then( r => r.json())
            .then(bList => {
                setFinderYearBookings(bList)
            }).catch(err => console.log(err))
    }

    async function getFullSchoolList() {
        await fetch('https://mrwp.kardall.com/schools/all', {
            method: 'GET',
        })
        .then( r => r.json())
        .then(sList => {
            setManagerSchoolList(sList.data)
        })
    }

    // useEffect( () => {
    //     console.log(managerSchoolId)
    //     console.log(managerSchoolName)
    // }, [managerSchoolId])

    const manage_schools_form_html = () => {
        if(managerSchoolList.length === 0) {
            getFullSchoolList()
        }

        function handleSchoolFormSubmit(e, school_id = 0) {
            e.preventDefault()
            

            if(managerSchoolId === 0) {
                // New School
                var school_data = {
                    school_name: managerSchoolName,
                    school_address1: managerSchoolAddress1,
                    school_address2: managerSchoolAddress2,
                    school_postalcode: managerSchoolPostalCode,
                    school_phone: managerSchoolPhone,
                    school_email: managerSchoolEmail,
                    school_contact: managerSchoolContact,
                    school_contact_phone: managerSchoolContactPhone
                }
                console.log("NEW SCHOOL")
                console.log(school_data)
                fetch('/addschool', {
                    method: 'POST',
                    headers: {
                        'Content-Type':'application/json',
                    },
                    body: JSON.stringify(school_data)
                })
            } else {
                // Update School
                //UpdateManagerSelectedSchoolData()
                var school_data = {
                    school_id: managerSchoolId,
                    school_name: managerSchoolName,
                    school_address1: managerSchoolAddress1,
                    school_address2: managerSchoolAddress2,
                    school_postalcode: managerSchoolPostalCode,
                    school_phone: managerSchoolPhone,
                    school_email: managerSchoolEmail,
                    school_contact: managerSchoolContact,
                    school_contact_phone: managerSchoolContactPhone
                }
                console.log("UPDATE SCHOOL")
                console.log(school_data)
                fetch('/addschool', {
                    method: 'POST',
                    headers: {
                        'Content-Type':'application/json',
                    },
                    body: JSON.stringify(school_data)
                })
            }
            getFullSchoolList()
        }

        function showManagerBlankForm() {

            // setManagerSchoolId("")
            // setManagerSchoolName("")
            // setManagerSchoolAddress1("")
            // setManagerSchoolAddress2("")
            // setManagerSchoolPostalCode("")
            // setManagerSchoolContact("")
            // setManagerSchoolContactPhone("")

            return (
                <Fragment>
                    <form>
                    <div key={"addr1"} className="booking-row">
                        <div className="booking-col">Name: <input value={managerSchoolName} onChange={(v) => setManagerSchoolName(v.target.value)} /></div>         
                    </div>
                    <div key={"addr2"} className="booking-row">
                        <div className="booking-col">Address 1: <input value={managerSchoolAddress1} onChange={(v) => setManagerSchoolAddress1(v.target.value)}/></div>         
                        <div className="booking-col">Address 2: <input value={managerSchoolAddress2} onChange={(v) => setManagerSchoolAddress2(v.target.value)}/></div>         
                        <div className="booking-col">Postal Code: <input value={managerSchoolPostalCode} onChange={(v) => setManagerSchoolPostalCode(v.target.value)}/></div>         
                        <div className="booking-col">Phone: <input value={managerSchoolPhone} onChange={(v) => setManagerSchoolPhone(v.target.value)}/></div>         
                        <div className="booking-col">Email: <input value={managerSchoolEmail} onChange={(v) => setManagerSchoolEmail(v.target.value)}/></div>         
                    </div>
                    <div key={"addr3"} className="booking-row">
                        <div className="booking-col">Contact: <input value={managerSchoolContact} onChange={(v) => setManagerSchoolContact(v.target.value)}/></div>         
                        <div className="booking-col">Contact Phone: <input value={managerSchoolContactPhone} onChange={(v) => setManagerSchoolContactPhone(v.target.value)}/></div>         
                    </div>
                    <div key={"addr4"} className="booking-row">
                        <button onClick={(e) => {
                            handleSchoolFormSubmit(e)
                        }}>Save</button>
                    </div>
                    </form>
                </Fragment>
            )
        }

        function displaySchoolData() {
            return (
                <Fragment>
                    <div key={managerSchoolName + "r1"} className="booking-row">
                        <div className="booking-col">ID: <input readOnly={true} defaultValue={managerSchoolId}/></div>
                        <div className="booking-col">Name: <input value={managerSchoolName} onChange={(v) => { setManagerSchoolName(v.target.value) } }/></div>         
                    </div>
                    <div key={managerSchoolName + "r2"} className="booking-row">
                        <div className="booking-col">Address 1: <input value={managerSchoolAddress1} onChange={(v) => setManagerSchoolAddress1(v.target.value)}/></div>         
                        <div className="booking-col">Address 2: <input value={managerSchoolAddress2} onChange={(v) => setManagerSchoolAddress2(v.target.value)}/></div>         
                        <div className="booking-col">Postal Code: <input value={managerSchoolPostalCode} onChange={(v) => setManagerSchoolPostalCode(v.target.value)}/></div>         
                        <div className="booking-col">Phone: <input value={managerSchoolPhone} onChange={(v) => setManagerSchoolPhone(v.target.value)}/></div>         
                        <div className="booking-col">Email: <input value={managerSchoolEmail} onChange={(v) => setManagerSchoolEmail(v.target.value)}/></div>         
                    </div>
                    <div key={managerSchoolName + "r3"} className="booking-row">
                        <div className="booking-col">Contact: <input value={managerSchoolContact} onChange={(v) => setManagerSchoolContact(v.target.value)}/></div>         
                        <div className="booking-col">Contact Phone: <input value={managerSchoolContactPhone} onChange={(v) => setManagerSchoolContactPhone(v.target.value)}/></div>         
                    </div>
                    <div key={managerSchoolName + "r4"} className="booking-row">
                        <button onClick={(e) => {
                            handleSchoolFormSubmit(e)
                        }}>Save</button>
                    </div>
                </Fragment>
            )
            
        }

        function getUniqueSchoolListEntries() {
            var schools = []
            managerSchoolList.forEach(school_entry => {
                if(!schools.includes(school_entry.school_name)) {
                    schools.push(school_entry.school_name)
                }
            })
            
            return (
                <select onChange={(v) => v.target.value !== '-- Select School --' ? updateManagerSelectedSchool(v.target.value) : setManagerShowAddSchoolForm(false)}>
                    <option defaultValue={true}>-- Select School --</option>
                    {schools.map(s => <option key={s} value={s}>{s}</option>)}
                </select>
            )
        }

        return (
            <div className="modal">
                <button className="modal-close-button" onClick={() => {setShowManagerForm(false);setManagerSchoolList([]);setManagerSelectedSchool("");}}>Close</button>
                    <button onClick={() => { clearManagerSchoolForm()}}>Add</button>
                <div key={"finding"} className="booking-new-container">
                    <div className="booking-row">
                        <div className="booking-col">
                            {getUniqueSchoolListEntries()}
                        </div>
                    </div>
                    {
                        managerShowAddSchoolForm ? showManagerBlankForm() : ""
                    }
                    {
                        managerSelectedSchool && managerShowAddSchoolForm === false ? displaySchoolData() : ""
                    }
                </div>
            </div>
        )

    }

    const finder_form_html = () => {


        if(finderYearBookings.length === 0) {
            getBookingsForCurrentYear()
        }
        function getUniqueSchoolEntryData() {
            if(finderSelectedSchool !== "" && finderSelectedSchool !== undefined) {
                // Get all the entries for the selected school
                return (
                    <>
                    {
                        finderYearBookings.map(b => {
                            if(b.school_name === finderSelectedSchool) {
                                return <><div key={b.booking_date + "_" + b.school_id} className="booking-col">{new Date(Date.parse(b.booking_date) + 86400000).toLocaleDateString('en-US',{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}<button onClick={() => { setShowFindForm(false);setFinderYearBookings([]);setFinderSelectedSchool("");setCurrentYear(new Date(Date.parse(b.booking_date) + 86400000).getFullYear()); setCurrentMonth(new Date(Date.parse(b.booking_date) + 86400000).getMonth()); ShowDetails({day: new Date(Date.parse(b.booking_date) + 86400000).getDate(), year: new Date(Date.parse(b.booking_date)).getFullYear(), month: new Date(Date.parse(b.booking_date)).getMonth()+1}) }}>View Booking</button></div></>
                            }
                            
                        })
                    }
                    </>
                )
            }
        }

        function getUniqueSchoolEntries() {
            var schools = []
            finderYearBookings.forEach(school_entry => {
                if(!schools.includes(school_entry.school_name)) {
                    schools.push(school_entry.school_name)
                }
            })

            return (
                <select onChange={(v) => setFinderSelectedSchool(v.target.value)}>
                    <option defaultValue={true}>-- Select School --</option>
                    {schools.map(s => <option key={s} value={s}>{s}</option>)}
                </select>
            )
        }
        return (
            <div className="modal">
                <div key={"finding"} className="booking-new-container">
                    <button className="modal-close-button" onClick={() => {setShowFindForm(false);setFinderYearBookings([]);setFinderSelectedSchool("");}}>Close</button>
                        <div className="booking-row">
                            <div className="booking-col">
                                {getUniqueSchoolEntries()}
                            </div>
                        </div>
                        <div className="booking-row">
                            <div className="booking-col">
                                {getUniqueSchoolEntryData()}
                            </div>
                        </div>
                </div>
            </div>
        )
    }

    const blank_form_html = () => {
        return (
            <Fragment>
            <div key={"newentry"} className="booking-new-container">
                <div className="booking-row">
                    <div className="booking-col">
                        <h3>Booking For { currentYear + "-" + (currentMonth+1).toString().padStart(2,'0') + "-" + selectedDay.toString().padStart(2,'0')}</h3>
                    </div>
                </div>
                <div className="booking-row">
                    <div className="booking-col">
                        <label>School</label>
                        <select onChange={(e) => setSchoolId(e.target.value)} className="school-select">
                            <option>-- Select School --</option>
                            {
                                schoolList.map( school => {
                                        if(schoolId === school.school_id) {
                                            return (
                                                <option key={school.school_id} selected={true} className="school-name" value={school.school_id}>{school.school_name}</option>
                                                )
                                        } else {
                                            return (
                                                <option key={school.school_id} className="school-name" value={school.school_id}>{school.school_name}</option>
                                                )
                                        }
                                    
                                })
                            }
                        </select>
                    </div>

                    
                </div>
                <div className="booking-row contact">
                    <div className="booking-col">
                        <label>Contact Name</label>
                        <input type="text" value={bookingContactName} onChange={(e) => setBookingContactName(e.target.value)}></input>
                    </div>
                    <div className="booking-col">
                        <label>Contact Phone</label>
                        <input type="text" value={bookingContactPhone} onChange={(e) => setBookingContactPhone(e.target.value)}></input>
                    </div>
                    <div className="booking-col">
                        <label>Contact Email</label>
                        <input type="text" value={bookingContactEmail} onChange={(e) => setBookingContactEmail(e.target.value)}></input>
                    </div>
                </div>
                <div className="booking-row contact">
                    <div className="booking-col">
                        <label>Contact 2 Name</label>
                        <input type="text" value={bookingContact2Name} onChange={(e) => setBookingContact2Name(e.target.value)}></input>
                    </div>
                    <div className="booking-col">
                        <label>Contact 2 Phone</label>
                        <input type="text" value={bookingContact2Phone} onChange={(e) => setBookingContact2Phone(e.target.value)}></input>
                    </div>
                    <div className="booking-col">
                        <label>Contact 2 Email</label>
                        <input type="text" value={bookingContact2Email} onChange={(e) => setBookingContact2Email(e.target.value)}></input>
                    </div>
                </div>
                <div className="booking-row">
                    <div className="booking-col">
                        <label>Size</label>
                        <input type="number" value={bookingGroup} onChange={(e) => setBookingGroup(e.target.value)}></input>
                    </div>
                    <div className="booking-col">
                        <label>Arrival Time</label>
                        {/* <input type="text" value={bookingArrival} onChange={(e) => setBookingArrival(e.target.value)}></input> */}
                        <TimePicker amPmAriaLabel="Select AM/PM" disableClock={true} disableIcon={true} value={bookingArrival} onChange={(e) => setBookingArrival(e)} />
                    </div>
                    <div className="booking-col">
                        <label>Departure Time</label>
                        {/* <input type="text" value={bookingDeparture}  onChange={(e) => setBookingDeparture(e.target.value)}></input> */}
                        <TimePicker amPmAriaLabel="Select AM/PM"disableClock={true} disableIcon={true}  value={bookingDeparture} onChange={(e) => setBookingDeparture(e)} />
                    </div>
                </div>
                <div className="booking-row">
                    <div className="booking-col">
                        <label>Lo Grade</label>
                        <input type="number" value={bookingLoGrade} onChange={(e) => setBookingLoGrade(e.target.value)}></input>
                    </div>
                    <div className="booking-col">
                        <label>Hi Grade</label>
                        <input type="number" value={bookingHiGrade} onChange={(e) => setBookingHiGrade(e.target.value)}></input>
                    </div>
                    <div className="booking-col">
                        <label>Ski</label>
                        <input type="number" value={bookingSki} onChange={(e) => setBookingSki(e.target.value)}></input>
                    </div>
                    <div className="booking-col">
                        <label>Snowboard</label>
                        <input type="number" value={bookingSnowboard} onChange={(e) => setBookingSnowboard(e.target.value)}></input>
                    </div>
                    <div className="booking-col">
                        <label>Tube</label>
                        <input type="number" value={bookingTube} onChange={(e) => setBookingTube(e.target.value)}></input>
                    </div>
                </div>
                <div className="booking-row">
                    <div className="booking-col">
                        <label>Pizza Lunches</label>
                        <input type="number" value={bookingPizza} onChange={(e) => setBookingPizza(e.target.value)}></input>
                    </div>
                    <div className="booking-col">
                        <label>Lunch Time</label>
                        <TimePicker disableClock={true} clearIcon={null} clockIcon={null} amPmAriaLabel="Select AM/PM" value={bookingPizzaTime} onChange={(e) => setBookingPizzaTime(e)}/>
                    </div>
                    <div className="booking-col">
                        <label>Disabled</label>
                        <input type="number" value={bookingDisabled} onChange={(e) => setBookingDisabled(e.target.value)}></input>
                    </div>
                    <div className="booking-col">
                        <label>Disabled Notes</label>
                        <textarea value={bookingDisabledNotes} onChange={(e) => setBookingDisabledNotes(e.target.value)}></textarea>
                    </div>
                    <div className="booking-col">
                        <label>Lift Only</label>
                        <input type="number" value={bookingLiftOnly} onChange={(e) => setBookingLiftOnly(e.target.value)}></input>
                    </div>
                    <div className="booking-col">
                        <label>Add Tube</label>
                        <input type="number" value={bookingAddTube} onChange={(e) => setBookingAddTube(e.target.value)}></input>
                    </div>
                </div>
                <div className="booking-row">
                    <div className="booking-col">
                        <label>Booking Notes</label>
                        <textarea type="text" value={bookingNotes} onChange={(e) => setBookingNotes(e.target.value)}></textarea>
                    </div>
                </div>
                <div className="booking-row">
                    <div className="booking-col">
                        <button className="save" onClick={() => SaveBooking()}>Save</button>
                    </div>
                    <div className="booking-col">
                        <button className="cancel" onClick={() => CancelNewBooking()}>Cancel</button>
                    </div>
                </div>
            </div>
            </Fragment>
        )
    }

    function AddNewBooking(e) {
        setShowAddForm(true)
    }

    function SaveBooking() {
        // TODO: Save Booking
        //  Save, Send, Reset Data

        var bookingDate = currentYear + "-" + (currentMonth+1).toString().padStart(2,'0') + "-" + selectedDay.toString().padStart(2,'0')
        var data = {
            booking_id:bookingId,
            booking_school_id:schoolId,
            booking_notes:bookingNotes,
            booking_date:bookingDate,
            booking_arrival:bookingArrival,
            booking_departure:bookingDeparture,
            booking_contact_name:bookingContactName,
            booking_contact_phone:bookingContactPhone,
            booking_contact_email:bookingContactEmail,
            booking_contact2_name:bookingContact2Name,
            booking_contact2_phone:bookingContact2Phone,
            booking_contact2_email:bookingContact2Email,
            booking_group:bookingGroup,
            booking_lograde:bookingLoGrade,
            booking_higrade:bookingHiGrade,
            booking_ski:bookingSki,
            booking_snowboard:bookingSnowboard,
            booking_liftonly:bookingLiftOnly,
            booking_tube:bookingTube,
            booking_addtube:bookingAddTube,
            booking_pizza:bookingPizza,
            booking_pizza_time:bookingPizzaTime,
            booking_disabled:bookingDisabled,
            booking_disabled_notes:bookingDisabledNotes
        }

        // if(editSchoolData != null) {
        //     data['booking_id'] = bookingId    
        // }
        // console.log(data)
        // if(editSchoolData != null) {
        //     data = editSchoolData
        // }
        data['booking_date'] = bookingDate
        console.log(data)
        fetch('/addbooking', {
            method: 'POST',
            headers: {
                'Content-Type':'application/json',
                'Access-Control-Allow-Origin':'*'
            },
            body: JSON.stringify(data)
            
        }).then( () => {
            GenerateCalendar(currentYear, currentMonth)
        })
        setShowAddForm(false)
        setEditSchoolData(null)
        ResetFormData()
        setCurrentData([])
    }
    function CancelNewBooking() {
        setShowAddForm(false)
        setEditSchoolData(null)
        ResetFormData()
        setCurrentData([])
    }
    function CreateAdminModal() {
        if(currentData === undefined) return (<></>)
        if(currentData.length === 0) return (<></>)
        if(schoolList.length === 0) { GetSchoolList() }
        return (
            <div className="modal">
                <button className="modal-close-button" onClick={() => setCurrentData([])}>Close</button>
                <button onClick={(e) => AddNewBooking(e)}>Add</button>
                {
                    showAddForm ? blank_form_html() : ""
                }
                {
                    isAdmin && editSchoolData !== null ? edit_form_html() : ""
                }
                {
                    GenerateAdminModalData()
                }
            </div>
        )
    }
    return (
        <>
            <div className="calendar-container">
                <div className="calendar-navigation">
                    <button onClick={() => prevYear()}><MdFastRewind/></button>
                    <button>{currentYear}</button>
                    <button onClick={() => nextYear()}><MdFastForward/></button>
                    <span className="yearmonthspacer"></span>
                    <button onClick={() => prevMonth()}><MdFastRewind/></button>
                    <button>{MonthToString(currentMonth)}</button>
                    <button onClick={() => nextMonth()}><MdFastForward/></button>
                    {
                        isAdmin ? <button className="find-booking" onClick={() => FindBooking()}>Find Booking</button> : ""
                    }
                    {
                        isAdmin ? <button className="find-booking" onClick={() => ManageSchools()}>Manage Schools</button> : ""
                    }
                </div>
                    <table id="calendar">
                        <thead>
                            <tr className="weekdays">
                                <th>Sun</th><th>Mon</th><th>Tue</th><th>Wed</th><th>Thu</th><th>Fri</th><th>Sat</th>
                            </tr>
                        </thead>
                        <tbody>
                            {calendarData}
                        </tbody>
                    </table>
                    {
                        canViewDetails ? CreateModalData() : ""
                        
                    }
                    
                    {
                        isAdmin ? CreateAdminModal() : ""
                    }
                    {
                        (showFindForm && isAdmin) ? finder_form_html() : ""
                    }
                    {
                        (showManagerForm && isAdmin) ? manage_schools_form_html() : ""
                    }
                    
            </div>
        </>
    )
    
}